import { RecipientFieldEnum } from '@/enums'
import i18n from '@/plugins/i18n'
import FormItemsOption from '@/models/forms/FormItemsOption'

export let FormOptionsforSkeletonMap: Map<RecipientFieldEnum, FormItemsOption> = new Map()

FormOptionsforSkeletonMap.set(RecipientFieldEnum.SWIFT, {
  prop: 'bank_swift',
  label: 'Swift Code',
  type: 'text',
  toolTipIcon: {
    imgSrc: 'btn-info.svg',
    content: i18n.t('tooltip.swift_code') as string,
    placement: 'bottom-start'
  },
  value: undefined,
  placeHolder: 'Swift Code'
})
FormOptionsforSkeletonMap.set(RecipientFieldEnum.MASTER_CODE, {
  prop: 'master_code',
  label: i18n.t('sheet.field.master_code') as string,
  type: 'select',
  filterable: true,
  value: undefined,
  placeHolder: i18n.t('sheet.field.master_code') as string
})
FormOptionsforSkeletonMap.set(RecipientFieldEnum.ROUTING_NUMBER, {
  prop: 'bank_routing_number',
  label: i18n.t('sheet.field.electronic_routing_number') as string,
  type: 'text',
  toolTipIcon: {
    imgSrc: 'btn-info.svg',
    content: i18n.t('tooltip.ach_routing_number') as string,
    placement: 'bottom-start'
  },
  value: undefined,
  placeHolder: i18n.t('sheet.field.electronic_routing_number') as string
})
FormOptionsforSkeletonMap.set(RecipientFieldEnum.SORT_CODE, {
  prop: 'bank_sort_code',
  label: i18n.t('sheet.field.bank_sort_code') as string,
  type: 'text',
  toolTipIcon: {
    imgSrc: 'btn-info.svg',
    content: i18n.t('tooltip.sort_code') as string,
    placement: 'bottom-start'
  },
  value: undefined,
  placeHolder: i18n.t('sheet.field.bank_sort_code') as string
})
FormOptionsforSkeletonMap.set(RecipientFieldEnum.IFSC, {
  prop: 'bank_ifsc',
  label: i18n.t('sheet.field.bank_ifsc') as string,
  type: 'text',
  toolTipIcon: {
    imgSrc: 'btn-info.svg',
    content: i18n.t('tooltip.ifsc') as string,
    placement: 'bottom-start'
  },
  value: undefined,
  placeHolder: i18n.t('sheet.field.bank_ifsc') as string
})
FormOptionsforSkeletonMap.set(RecipientFieldEnum.BSB_CODE, {
  prop: 'bank_bsb_code',
  label: i18n.t('sheet.field.bsb_code') as string,
  type: 'text',
  toolTipIcon: {
    imgSrc: 'btn-info.svg',
    content: i18n.t('tooltip.bsb_code') as string,
    placement: 'bottom-start'
  },
  value: undefined,
  placeHolder: i18n.t('sheet.field.bsb_code') as string
})
FormOptionsforSkeletonMap.set(RecipientFieldEnum.CARD_NUMBER, {
  prop: 'debit_card_number',
  label: i18n.t('sheet.field.card_number') as string,
  type: 'text',
  value: undefined,
  placeHolder: i18n.t('sheet.field.card_number') as string
})
FormOptionsforSkeletonMap.set(RecipientFieldEnum.IBAN, {
  prop: 'bank_iban',
  label: i18n.t('sheet.field.bank_iban') as string,
  type: 'text',
  value: undefined,
  placeHolder: i18n.t('sheet.field.bank_iban') as string
})
FormOptionsforSkeletonMap.set(RecipientFieldEnum.BANK_BRANCH, {
  prop: 'bank_branch',
  label: i18n.t('sheet.field.bank_branch') as string,
  type: 'text',
  placeHolder: i18n.t('sheet.field.bank_branch') as string,
  value: undefined
})
FormOptionsforSkeletonMap.set(RecipientFieldEnum.TRANSIT_CODE, {
  prop: 'bank_transit_code',
  label: i18n.t('sheet.field.bank_transit_code') as string,
  type: 'text',
  toolTipIcon: {
    imgSrc: 'btn-info.svg',
    content: i18n.t('tooltip.transit_code') as string,
    placement: 'bottom-start'
  },
  placeHolder: i18n.t('sheet.field.bank_transit_code') as string,
  value: undefined
})
FormOptionsforSkeletonMap.set(RecipientFieldEnum.ACCOUNT_NUMBER, {
  prop: 'bank_account_number',
  label: i18n.t('sheet.field.bank_account_number') as string,
  type: 'text',
  value: undefined,
  placeHolder: i18n.t('sheet.field.bank_account_number') as string
})
FormOptionsforSkeletonMap.set(RecipientFieldEnum.ADDRESS_POSTAL, {
  prop: 'postal_code',
  label: i18n.t('sheet.field.postal') as string,
  type: 'text',
  placeHolder: i18n.t('sheet.field.postal') as string,
  value: undefined
})
FormOptionsforSkeletonMap.set(RecipientFieldEnum.ADDRESS_CITY, {
  prop: 'city',
  label: i18n.t('sheet.field.city') as string,
  type: 'text',
  placeHolder: i18n.t('sheet.field.city') as string,
  value: undefined
})
FormOptionsforSkeletonMap.set(RecipientFieldEnum.ADDRESS_LINE1, {
  prop: 'line1',
  label: i18n.t('sheet.field.line2') as string,
  type: 'text',
  placeHolder: i18n.t('sheet.field.line2') as string,
  value: undefined
})
FormOptionsforSkeletonMap.set(RecipientFieldEnum.ID_CARD_TYPE, {
  prop: 'id_card_type',
  label: i18n.t('sheet.field.id_type') as string,
  type: 'select',
  placeHolder: i18n.t('sheet.field.id_type') as string,
  value: undefined
})
FormOptionsforSkeletonMap.set(RecipientFieldEnum.ID_CARD_NUMBER, {
  prop: 'id_card_number',
  label: i18n.t('sheet.field.id_number') as string,
  type: 'text',
  placeHolder: i18n.t('sheet.field.id_number') as string,
  value: undefined
})