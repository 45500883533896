import { ParsedRecipient } from '@/presentation/recipient/model/ParsedRecipient'
import { UploadValidResponse } from '@/usecase/recipient/model/UploadValidResponse'
import { MessageBox } from 'element-ui'
import i18n from '@/plugins/i18n'
import { IRecipientUseCase, RecipientUseCase } from '@/usecase/recipient/RecipientUseCase'
import { ISheetHandlerUseCase, SheetHandlerUseCase } from '@/usecase/sheet/SheetHandlerUseCase'
import { swiftCodesDictionary } from '@/data/masterCodes/SwiftCodes'
import { countryMasterCodes } from '@/data/masterCodes/countryMasterCodes'

export interface IUploadRecipientPresentation {
  showValidationMessage (): Promise<void>;
  modifySwiftToBankName (): void 
  getParsedRecipient (file: Blob): Promise<Dictionary<Array<ParsedRecipient>>>
  isValidExcel (): Promise<boolean>
}

export class UploadRecipientPresentation implements IUploadRecipientPresentation {
  recipientUseCase: IRecipientUseCase
  sheetHandlerUseCase: ISheetHandlerUseCase
  parsedRecipient: Dictionary<Array<ParsedRecipient>>
  responseMessages: Map<UploadValidResponse, string>
  uploadValidResponse: UploadValidResponse
  constructor () {
    this.recipientUseCase = new RecipientUseCase()
    this.sheetHandlerUseCase = new SheetHandlerUseCase()
    this.parsedRecipient = {}
    this.uploadValidResponse = UploadValidResponse.SUCCESS
    this.responseMessages = new Map<UploadValidResponse, string>([
      [UploadValidResponse.SUCCESS, i18n.t('notification.uploaded') as string],
      [UploadValidResponse.FAIL, i18n.t('notification.errors.excel_wrong') as string],
      [UploadValidResponse.EMPTY_SHEETS, i18n.t('notification.errors.empty_sheets') as string],
      [UploadValidResponse.INVALID_COLUMN_HEADERS, i18n.t('notification.errors.invalid_column_headers') as string],
      [UploadValidResponse.NOT_ANALOGUE_CORP_WITH_OTHERS, i18n.t('notification.errors.unavailable_country') as string],
      [UploadValidResponse.NO_ACCEPTABLE_COUNTRIES, i18n.t('notification.errors.unavailable_country') as string],
      [UploadValidResponse.SERVER_ERROR, i18n.t('notification.errors.server') as string]
    ])
  }

  async showValidationMessage (): Promise<void> {
    const message: string = this.responseMessages.get(this.uploadValidResponse) || this.responseMessages.get(UploadValidResponse.FAIL) as string
    await MessageBox.alert(message, {
      showCancelButton: true,
      showConfirmButton: false,
      dangerouslyUseHTMLString: true,
      cancelButtonText: `${i18n.t('commons.close')}`,
      center: true
    })
  }

  modifySwiftToBankName (): void {
    Object.keys(this.parsedRecipient).forEach(bankName => {
      if (bankName.includes('VN')) {
        this.parsedRecipient[bankName].forEach(recipient => {
          const isSwift = !!Object.keys(swiftCodesDictionary).find(key => key === recipient.master_code)
          const swiftToMaterCode = swiftCodesDictionary[recipient.master_code]
          recipient.master_code = isSwift ? 
            `${Object.keys(countryMasterCodes['VN']).find(key => countryMasterCodes['VN'][key] === swiftToMaterCode)}`
            : recipient.master_code
        })
      }
    })
  }

  async getParsedRecipient (file: Blob): Promise<Dictionary<Array<ParsedRecipient>>> {
    const parsedJson: Dictionary<any> = await this.sheetHandlerUseCase.parseSheet(file)
    this.parsedRecipient = await this.recipientUseCase.getRecipientFromExcel(parsedJson)
    this.modifySwiftToBankName()
    return this.parsedRecipient
  }

  async isValidExcel (): Promise<boolean> {
    this.uploadValidResponse = await this.recipientUseCase.isValidExcel(this.parsedRecipient)
    const isSuccessResponse = Math.floor(Number(this.uploadValidResponse) / 100) === 2
    return isSuccessResponse
  }
}
