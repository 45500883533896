import { Message } from 'element-ui'
import { ISheetHandlerEntity, SheetHandlerEntity } from '@/entity/sheet/SheetHandlerEntity'

export interface ISheetHandlerUseCase {
  parseSheet (file: Blob): Promise<Dictionary<any>>
}

export class SheetHandlerUseCase implements ISheetHandlerUseCase {
  private entity: ISheetHandlerEntity
  constructor () {
    this.entity = new SheetHandlerEntity()
  }
  public async parseSheet (file: Blob): Promise<Dictionary<any>> {
    let parsedJson: Dictionary<any> = {}
    try {
      parsedJson = await this.entity.parseSheet(file, { range: 0 })
    } catch (error) {
      Message({ message: error.message, type: 'error' })
    }
    return parsedJson
  }
}
