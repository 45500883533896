
import Vue from 'vue'
import Component from 'vue-class-component'
import FormItems from '@/components/shared/FormItems.vue'
import { IIndividualRecipientModalPresentation, IndividualRecipientModalPresentation } from '@/presentation/recipient/IndividualRecipientModalPresentation'

@Component({
  components: { FormItems }
})
export default class RegisterIndividualRecipientModal extends Vue {
  modalPresentation: IIndividualRecipientModalPresentation = new IndividualRecipientModalPresentation()

  /**
   * '수취 국가' 셀렉트에서 change 이벤트가 발생했을 때 실행한다.
   */
  onChangeNation (): void {
    this.modalPresentation.onChangeNation()
  }

  /**
   * '수취 국가' 셀렉트에서 change 이벤트가 발생했을 때 실행한다.
   */
  onChangeCurrency (): void {
    this.modalPresentation.onChangeCurrency()
  }

  /**
   * '수취인 타입' 라디오에서 change 이벤트가 발생했을 때 실행한다.
   */
  onChangeIndividual (): void {
    this.modalPresentation.onChangeIndividual()
  }

  /**
   * '수취 수단' 라디오에서 change 이벤트가 발생했을 때 실행한다.
   */
  onChangeRemittanceMethod (): void {
    this.modalPresentation.onChangeRemittanceMethod()
  }

  /**
   * 폼의 유효화 여부를 판단한다.
   */
  async isValidated (): Promise<boolean> {
    return this.modalPresentation.isEveryFieldFilled
  }

  /**
   * '다음' 버튼을 눌렀을 때 폼의 유효화검사를 진행하고, 수취인을 등록한다.
   * 
   * - 유효화검사를 통과하지 못한 경우 에러 메시지 노출
   * - 수취인 등록에 실패한 경우 에러메시지 노출
   * - 성공적으로 등록한 경우 'successfullyCreated' emit
   */
  async onClickSubmit (): Promise<void> {
    const isvalid: boolean = await this.modalPresentation.formValidate(this.$refs.individualRegisterForm)
    if (!isvalid) {
      this.$message({ message: `${this.$t('notification.errors.recheck')}`, type: 'error' })
      return
    }
    const isCreationFailed: boolean = await this.modalPresentation.createRecipient()
    if (isCreationFailed) {
      this.$message({ message: `${this.$t('notification.errors.error')} ${this.$t('notification.errors.cs_center')}`, type: 'error' })
      return
    }
    const hasCorpName: boolean = this.modalPresentation.formField.corp_name.length > 0
    const successfullyCreatedName: string = hasCorpName ? `${this.modalPresentation.formField.corp_name}` : `${this.modalPresentation.formField.first_name} ${this.modalPresentation.formField.last_name}`
    this.$emit('successfullyCreated', successfullyCreatedName)
  }

  /**
   * created life cycle에 modalPresentation init 함수를 호출한다.
   */
  async created (): Promise<void> {
    await this.modalPresentation.init()
  }
}
