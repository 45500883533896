import { IRecipientUseCase, RecipientUseCase } from '@/usecase/recipient/RecipientUseCase'
import i18n from '@/plugins/i18n'
import CountryEnum from '@/enums/CountryEnum'
import { IOtherCountry } from '@/components/recipient/model/IOtherCountry'
import { Utils } from '@/static/Utils'
import { staticValues } from '@/static/StaticValues'
import { Message } from 'element-ui'

export interface IDownloadRecipientPresentation {
  isAnalogueCorp: boolean;
  sortedReceivableCountries: Array<{ id: number; iso: string; }>;
  receivableCountriesIso: Array<string>;
  selectedCountries: Array<string>;
  selectedOtherCountries: Array<string>;
  otherCountryState: string;
  otherCountrySuggestions: Array<IOtherCountry>;
  searchedOtherCountries: Array<IOtherCountry>;

  onSelectOtherCountry (item: IOtherCountry): void;

  onDeCheckedOtherCountry (): void;

  isSelectedCountry (countryIso: keyof typeof CountryEnum): boolean;

  download (): Promise<void>;

  initialize (): Promise<boolean>;
}

export class DownloadRecipientPresentation implements IDownloadRecipientPresentation {
  private useCase: IRecipientUseCase
  isAnalogueCorp = false
  sortedReceivableCountries: Array<{ id: number; iso: string; }> = []
  receivableCountriesIso: Array<string> = []
  selectedCountries: Array<string> = []
  selectedOtherCountries: Array<string> = []
  otherCountryState = ''
  otherCountrySuggestions: Array<IOtherCountry> = []
  searchedOtherCountries: Array<IOtherCountry> = []

  constructor () {
    this.useCase = new RecipientUseCase()
  }

  isSelectedCountry (countryIso: keyof typeof CountryEnum): boolean {
    return this.selectedCountries.includes(countryIso)
  }

  private setOtherCountryProps () {
    this.otherCountrySuggestions = Utils.sortedCountryCodes().filter(country => {
      return !staticValues.availableCountriesIso.includes(country.iso)
    }).map(country => {
      return { value: Utils.isKoreanLocale ? country.name_korean : country.name, iso: country.iso }
    })
    this.searchedOtherCountries = this.selectedOtherCountries.map(country => {
      const searchedCountry = this.otherCountrySuggestions.find(suggestionCountry => suggestionCountry.iso === country)
      return {
        iso: country,
        value: searchedCountry?.value || ''
      }
    })
  }

  onSelectOtherCountry (item: IOtherCountry): void {
    const isSelectedAlready = this.selectedOtherCountries && this.selectedOtherCountries.includes(item.iso)
    if (isSelectedAlready) return
    this.searchedOtherCountries.push(item)
    this.otherCountryState = ''
    this.selectedOtherCountries.push(item.iso)
  }

  onDeCheckedOtherCountry (): void {
    this.searchedOtherCountries = this.searchedOtherCountries.filter(country => {
      return this.selectedOtherCountries.includes(country.iso)
    })
  }

  async download (): Promise<void> {
    const isEmptyChecked = (!this.isAnalogueCorp && !this.selectedCountries.length) || (this.isAnalogueCorp && !this.selectedCountries.length && !this.selectedOtherCountries.length)
    if (isEmptyChecked) {
      Message({ message: `${i18n.t('commons.select_all_received_country')}`, type: 'info' })
      return
    }
    await this.useCase.downloadRecipientSheet(this.selectedCountries, this.selectedOtherCountries)
  }

  async initialize (): Promise<boolean> {
    this.isAnalogueCorp = await this.useCase.isAnalogueCorp()
    const countries = await this.useCase.getReceivableCountries()
    this.receivableCountriesIso = countries.sort((countryA, countryB) => {
      const a = i18n.t(`country.${[countryA.toLowerCase()]}`)
      const b = i18n.t(`country.${[countryB.toLowerCase()]}`)
      return a < b ? -1 : a > b ? 1 : 0
    })
    this.sortedReceivableCountries = this.receivableCountriesIso.map(country => {
      return { id: CountryEnum[country as keyof typeof CountryEnum] as number, iso: country as string }
    })
    this.setOtherCountryProps()
    return !!this.sortedReceivableCountries.length
  }
}
