import { sheetHandlerOption } from '@/lib/SheetHandler'
import i18n from '@/plugins/i18n'
import store from '@/store'
import { read, utils, WorkBook } from 'xlsx'

export interface ISheetHandlerEntity {
  parseSheet (file: Blob, options?: sheetHandlerOption): Promise<Dictionary<any>>;
}

export class SheetHandlerEntity implements ISheetHandlerEntity {
  private async sheetToWorkBook (file: Blob): Promise<WorkBook> {
    const workBook: WorkBook = await new Promise((resolve) => {
      const reader = new FileReader()
      reader.onload = async (e: any) => {
        let binary = ''
        const bytes = new Uint8Array(e.target?.result)
        bytes.forEach(bin => {
          binary += String.fromCharCode(bin)
        })
        const workBook: WorkBook = read(binary, { type: 'binary' })
        resolve(workBook)
      }
      reader.readAsArrayBuffer(file)
    })
    return workBook
  }

  private filterEmptyHeader (sheetJson: Array<any>): Array<any> {
    const filteredSheetJson: Array<any> = []
    const baseColumns = Object.keys(Object.assign({}, sheetJson[0]))
    sheetJson = sheetJson.slice(1)
    sheetJson.forEach(row => {
      const initialRow: Dictionary<any> = {}
      baseColumns.forEach(baseColumn => {
        if (!row[baseColumn]) initialRow[baseColumn] = ''
        initialRow[baseColumn] = row[baseColumn]
      })
      const columnKeys = Array(...Object.keys(initialRow))
      columnKeys.forEach((key: string) => {
        if (key.includes('__EMPTY_')) delete initialRow[key]
      })
      const isExistAnyColumn = !!Object.keys(initialRow).length
      if (isExistAnyColumn) filteredSheetJson.push(initialRow)
    })
    return filteredSheetJson
  }

  private workBookToJson (workBook: WorkBook, range = 0): Dictionary<any> {
    const sheetJson: Dictionary<any> = {}
    workBook.SheetNames.forEach((workSheetName: string) => {
      const workSheet = workBook.Sheets[workSheetName]
      let workSheetJson: Array<any> = utils.sheet_to_json(workSheet, { range })
      workSheetJson = this.filterEmptyHeader(workSheetJson)
      if (workSheetJson.length) sheetJson[workSheetName] = workSheetJson
    })
    return sheetJson
  }

  public async parseSheet (file: Blob, options?: sheetHandlerOption): Promise<Dictionary<any>> {
    store.commit('enableLoading', i18n.t('notification.loading.upload_excel'))
    await new Promise(resolve => setTimeout(resolve, 50))
    const workBook: WorkBook = await this.sheetToWorkBook(file)
    return this.workBookToJson(workBook, options?.range)
  }
}
