const UnsupportedBankCountriesForUSD: string[] = [
  'AT',
  'SE',
  'BE',
  'DK',
  'BG',
  'EE',
  'FI',
  'CY',
  'FR',
  'DE',
  'MT',
  'IE',
  'MC',
  'IT',
  'NL',
  'LV',
  'PL',
  'LU',
  'PT',
  'ES',
  'LT',
  'AU',
  'BD',
  'CA',
  'CN',
  'GB',
  'ID',
  'IN',
  'JP',
  'KH',
  'LK',
  'MY',
  'NP',
  'PE',
  'PH',
  'PK',
  'SG',
  'TH',
  'TR',
  'US',
  'VN',
  'ZA'
];
export default UnsupportedBankCountriesForUSD;
