import { GetCorpInformation } from '@/gateway/commons/GetCorpInformation'
import { CorpInformation } from '@/gateway/commons/model/CorpInformation'
import { AvailableCurrencies } from '@/gateway/remittanceBase/AvailableCurrencies'
import { ReceivableCountries } from '@/gateway/remittanceBase/ReceivableCountries'
import { MasterCode } from '@/gateway/sheet/MasterCode'
import { CountryPhoneCodes } from '@/data/CountryPhoneCodes'
import { FormOptionsforSkeletonMap } from '@/data/FormOptionsForSkeleton'
import { countryMasterCodesWithCurrency } from '@/data/masterCodes/countryMasterCodesWithCurrency'
import { IGetMasterCodeOption } from '@/entity/validator/data/RecipientRules'
import { RecipientFieldEnum } from '@/enums'
import { FormValidator } from '@/presentation/FormValidator'
import { CountryCode, Utils } from '@/static/Utils'
import FormItemsOption from '@/models/forms/FormItemsOption'
import { RecipientFormRules } from '@/data/RecipientFormRules'
import MemberInfo from '@/models/MemberInfo'
import { MemberInfoGateway } from '@/gateway/commons/MemberInfoGateway'
import FormRule from '@/models/forms/FormRule'

export interface IIndividualRecipientUseCase {
  readonly isKoreanLocale: boolean

  readonly formOptionsForSkeletonMap: Map<RecipientFieldEnum, FormItemsOption>
  readonly recipientFormRules: Dictionary<Array<FormRule>>

  readonly countryMasterCodesWithCurrency: Dictionary<Dictionary<Dictionary<number>>>

  readonly CountryPhoneCodes: Array<CountryCode>

  formValidate (form: any): Promise<boolean | any>;
  getSortedCountryCodes (): Array<CountryCode>;
  getIsoWithName (): Promise<Array<{ iso: string, name: string }>>;
  getReceivableCurrencies (): Promise<Dictionary<Array<string>>>;
  getReceivableCountries (): Promise<Array<{ iso: string, name: string }>>;
  getCorpInformation (): Promise<CorpInformation>;
  getMemberInfo (): Promise<MemberInfo>;
  getMasterCodesWithCountry (option: IGetMasterCodeOption): Dictionary<number>;
  init (): Promise<void>;
}

export class IndividualRecipientUseCase implements IIndividualRecipientUseCase {
  private formValidator: FormValidator = new FormValidator()
  private isAnalogueCorp: boolean = false

  constructor () {}

  /**
   * 사용자의 locale이 'en'이 아닌지 판단여부를 나타낸다.
   * 
   * @type {boolean} 'en'이 아니면 true
   */
  get isKoreanLocale (): boolean {
    return Utils.isKoreanLocale
  }

  /**
   * FormOptionsforSkeletonMap을 나타낸다
   * 
   * @type {Map<RecipientFieldEnum, FormItemsOption>}
   */
  get formOptionsForSkeletonMap (): Map<RecipientFieldEnum, FormItemsOption> {
    return FormOptionsforSkeletonMap
  }

  /**
   * RecipientFormRules를 나타낸다.
   * 
   * @type {Dictionary<Array<FormRule>>}
   */
  get recipientFormRules (): Dictionary<Array<FormRule>> {
    return RecipientFormRules
  }

  /**
   * countryMasterCodesWithCurrency를 나타낸다.
   * 
   * @type {Dictionary<Dictionary<Dictionary<number>>>}
   */
  get countryMasterCodesWithCurrency (): Dictionary<Dictionary<Dictionary<number>>> {
    return countryMasterCodesWithCurrency
  }

  /**
   * CountryPhoneCodes를 나타낸다.
   * 
   * @type {Array<CountryCode>}
   */
  get CountryPhoneCodes (): Array<CountryCode> {
    return CountryPhoneCodes
  }

  /**
   * 폼의 유효화를 검사한다.
   * 
   * @param form 검사할 폼의 ref
   * @returns {Promise<boolean | any>} 검사 결과. 유효할 경우 true, 유효하지 않을 경우 error
   * 
   * @async
   */
  async formValidate (form: any): Promise<boolean | any> {
    const response: boolean | any = await this.formValidator.validate(form)
    return response
  }

  /**
   * sortedCountryCodes를 리턴한다.
   * 
   * @returns {Array<CountryCode>} sortedCountryCodes
   */
  getSortedCountryCodes (): Array<CountryCode> {
    return Utils.sortedCountryCodes()
  }

  /**
   * ReceivableCountries의 iso, name 객체 배열을 가져와 리턴한다.
   * 
   * @returns {Promise<Array<{ iso: string, name: string }} iso, name 객체 배열
   * 
   * @async
   */
  async getIsoWithName (): Promise<Array<{ iso: string, name: string }>> {
    const response: Array<{ iso: string, name: string }> = await ReceivableCountries.getIsoWithName()
    return response
  }

  /**
   * AvailableCurrencies의 getReceivableCurrencies를 리턴한다.
   * 
   * @returns {Promise<Dictionary<Array<string>>>} getReceivableCurrencies
   * 
   * @async
   */
  async getReceivableCurrencies (): Promise<Dictionary<Array<string>>> {
    const response: Dictionary<Array<string>> = await AvailableCurrencies.getReceivableCurrencies()
    return response
  }

  /**
   * 사용자의 회사가 아날로그 송금 가능일 경우 sortedCountryCodes의 iso, name 객체의 배열을, 그렇지 않을 경우 receivableCountries의 iso, name객체의 배열을 리턴한다
   * 
   * @returns {Promise<Array<{ iso: string, name: string }>>} iso, name 객체의 배열
   * 
   * @async
   */
  async getReceivableCountries (): Promise<Array<{ iso: string, name: string }>> {
    const receivableCountries: Array<{ iso: string, name: string }> = this.isAnalogueCorp ? this.getSortedCountryCodes().map(code => {
      return {
        iso: code.iso,
        name: this.isKoreanLocale ? code.name_korean : code.name
      }
    }) : await this.getIsoWithName()

    return receivableCountries
  }

  /**
   * CorpInformation을 가져와 리턴한다.
   * 
   * @returns {Promise<CorpInformation>} CorpInformation
   * 
   * @async
   */
  async getCorpInformation (): Promise<CorpInformation> {
    const response: CorpInformation = await GetCorpInformation.get()
    return response
  }

  /**
   * MemberInfo를 가져와 리턴한다.
   * 
   * @returns {Promise<MemberInfo>} MemberInfo
   * 
   * @async
   */
  async getMemberInfo (): Promise<MemberInfo> {
    const response: MemberInfo = MemberInfoGateway.getMemberInfo()
    return response
  }

  /**
   * option에 따른 MasterCodes를 리턴한다.
   * 
   * @param {IGetMasterCodeOption} option getMasterCodesWithCountry 함수의 파라미터
   * @returns {Dictionary<number>} MasterCodes
   */
  getMasterCodesWithCountry (option: IGetMasterCodeOption): Dictionary<number> {
    return MasterCode.getMasterCodesWithCountry(option)
  }

  /**
   * GetCorpInformation을 통해 사용자 회사의 아날로그 여부를 가져와 isAnalogueCorp에 할당한다.
   * 
   * @async
   */
  async init (): Promise<void> {
    this.isAnalogueCorp = (await GetCorpInformation.get()).analogue
  }
}
